import React from 'react'
import {useParams} from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../redux/actions/paymentActions'
import { bindActionCreators } from 'redux'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import LoadingScreen from '../elements/LoadingScreen'
import FaceIcon from '@material-ui/icons/Face'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import { enums } from '../enums'

const mapStateToProps = state => ({
  payment: state.payment.data,
  isLoading: state.payment.isLoading,
  isError: state.payment.isError
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px'
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: '20px'
  },
  subtitle: {
    marginBottom: '15px'
  },
  iconContainer: {
    display: 'flex'
  },
  icon: {
    marginRight: '10px'
  },
  orderTopMargin: {
    marginTop: '50px'
  }
}));

function Payment({ payment, isError, isLoading, actions }) {
  const classes = useStyles();
  const { id } = useParams();

   // No payment
   if (payment === null && !isError && !isLoading) {
    actions.fetchPayment(id);
    return <LoadingScreen />;
  }

  // Payment changed
  if (payment && payment.Details.ID !== Number(id) && !isLoading && !isError) {
    actions.fetchPayment(id);
    return <LoadingScreen />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error</div>
  }

  return (
    <Paper square elevation={4} className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h4" noWrap>
            Payment # {id}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography component="h6" variant="h6" noWrap className={classes.subtitle}>
            Details
          </Typography>
          <Typography component="p" variant="body1" noWrap gutterBottom>
              <b>ParamX: </b>{payment.Details.ParamX}
            </Typography>
            <Typography component="p" variant="body1" noWrap gutterBottom>
              <b>Amount: </b>{payment.Details.Amount} {payment.Details.Currency}
            </Typography>
            <Typography component="p" variant="body1" noWrap gutterBottom>
              <b>Status: </b>{payment.Details.Status}
            </Typography>
            <Typography component="p" variant="body1" noWrap gutterBottom>
              <b>Token: </b>{payment.Details.Token}
            </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem className={classes.divider}/>
        <Grid item xs={5}>
          <Typography component="h6" variant="h6" noWrap className={classes.subtitle}>
            Account
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom className={classes.iconContainer}>
            <FaceIcon className={classes.icon} /> {payment.Account.FirstName} {payment.Account.LastName} {payment.Account.Country ? `, ${payment.Account.Country}` : ''}
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom className={classes.iconContainer}>
            <EmailIcon className={classes.icon} /> {payment.Account.Email}
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom className={classes.iconContainer}>
            <PhoneIcon className={classes.icon} /> {payment.Account.Phone}
          </Typography>
          <div className={classes.orderTopMargin}>
            <Typography component="h6" variant="h6" noWrap className={classes.subtitle}>
              Order
            </Typography>
            <Typography component="p" variant="body1" noWrap>
              <b>Subscription for: </b>{enums[payment.Order.OrderType]}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment)
