import React, { useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import * as actions from '../redux/actions/subscriptionsActions'
import { bindActionCreators } from 'redux'
import MUIDataTable from "mui-datatables"
import { useHistory } from "react-router-dom"
import LoadingScreen from '../elements/LoadingScreen'
import CancelIcon from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography } from '@material-ui/core'
import { cancelModalStatuses } from '../const'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { green } from '@material-ui/core/colors'
import clsx from 'clsx'

const mapStateToProps = state => ({
  subscriptions: state.subscriptions.data,
  isLoading: state.subscriptions.isLoading,
  isError: state.subscriptions.isError,
  cancelModalStatus: state.subscriptions.cancelModalStatus
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const useStyles = makeStyles((theme) => ({
  cancelIcon: {
    cursor: 'pointer'
  },
  dialog: {
    padding: '0 25px 20px 25px',
    textAlign: 'center'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingTop: '20px'
  },
  loadingContainer: {
    textAlign: 'center'
  },
  confirmButton: {
    color: '#FFF',
  },
  icon: {
    verticalAlign: 'top',
    marginRight: '5px'
  },
  successIcon: {
    color: green[500]
  },
  marginTop: {
    marginTop: '10px'
  }
}))

const columns = [
  "ID",
  "Date",
  "Amount",
  "Currency",
  "Last Payment",
  "Status",
  "Flag",
  "First Name",
  "Last Name",
  "Order Language",
  "Cancel"
];

const options = {
  selectableRows: 'none',
};

function SubscriptionsTable({ subscriptions, isError, isLoading, cancelModalStatus, actions }) {
  let history = useHistory();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDone = () => {
    if (cancelModalStatus === cancelModalStatuses.cancelSuccess) {
      actions.fetchSubscriptions();
    }
    actions.setModalStatus(cancelModalStatuses.buttons);
    setOpenModal(false);
  }

  const cancelSubscription = () => {
    actions.cancelSubscription(selectedSubscription);
  }

  options.onRowClick = (rowData, rowMeta) => {
    history.push(`/subscriptions/${rowData[0]}`);
  }

  options.onCellClick = (colData, cellMeta) => {
    if (cellMeta.colIndex === columns.length - 1) {
      cellMeta.event.stopPropagation();
      const subscriptionId = subscriptions[cellMeta.rowIndex][0];
      setSelectedSubscription(subscriptionId);
      setOpenModal(true);
    }
  }

  if (subscriptions === null && !isError && !isLoading) {
    actions.fetchSubscriptions();
    return <LoadingScreen />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error</div>
  }

  subscriptions = subscriptions.map(row => {
    return ([
      ...row,
      <div className={classes.cancelIcon} title='Cancel subscription'>
        <CancelIcon color="secondary" />
      </div>
    ])
  });

  return (      
    <>  
      <MUIDataTable
        title={"Subscriptions"}
        data={subscriptions}
        columns={columns}
        options={options}
      />
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={openModal}>
        <DialogTitle>Subscription cancellation</DialogTitle>
          <div className={classes.dialog}>
            { cancelModalStatus === cancelModalStatuses.buttons ?
              <>
                <Typography component="p" variant="body1">
                  Cancel subscription № {selectedSubscription}?
                </Typography>
                <div className={classes.buttonsContainer}>
                  <Button className={classes.confirmButton} color="secondary" variant="contained" onClick={cancelSubscription}>
                    Yes
                  </Button>
                  <Button variant="contained" onClick={handleClose}>
                    No
                  </Button>
                </div>
              </> :
            cancelModalStatus === cancelModalStatuses.cancelling ?
              <div className={classes.loadingContainer}>
                <Typography component="p" variant="body1" gutterBottom>
                  Cancelling subscription
                </Typography>
                <CircularProgress />
              </div> :
              <>
                {
                  cancelModalStatus === cancelModalStatuses.cancelSuccess ?
                    <div>
                      <CheckCircleIcon className={clsx(classes.icon, classes.successIcon)}/>
                      <Typography component="span" variant="body1">
                        Subscription was successfully cancelled
                      </Typography>
                    </div> :
                    <div>
                      <CancelIcon color="error" className={classes.icon}/>
                      <Typography component="span" variant="body1">
                        There is an error on subscription cancelling
                      </Typography>
                    </div>
                }               
                <Button className={classes.marginTop} variant="contained" onClick={handleDone}>
                  Ok
                </Button>
              </>
            }
          </div>
      </Dialog>
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsTable)