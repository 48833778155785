import * as types from '../actionTypes'
import moment from 'moment'

export const requestAccounts = () => ({ type: types.REQUEST_ACCOUNTS });
export const receiveAccounts =
  (accounts, isError) => ({
    type: types.RECEIVE_ACCOUNTS,
    accounts,
    isError
  })

export const fetchAccounts = () => async dispatch => {
  dispatch(requestAccounts());
  try {
      const url = `/admin/accounts`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND}${url}`);
      const responseBody = await response.json();
      const mappedData = [];
      responseBody.map(row => {
        mappedData.push([
          row.ID,
          moment(row.Date).format("MMMM Do YYYY"),
          row.FirstName,
          row.LastName,
          row.Email,
          row.Phone,
          row.OrderLanguage
        ])
      });

      dispatch(receiveAccounts(mappedData, false));
  } catch (error) {
    console.error(error);
    dispatch(receiveAccounts(null, true));
  }
}