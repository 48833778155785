import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../redux/actions/paymentsActions'
import { bindActionCreators } from 'redux'
import MUIDataTable from "mui-datatables"
import { useHistory } from "react-router-dom"
import LoadingScreen from '../elements/LoadingScreen'

const mapStateToProps = state => ({
  payments: state.payments.data,
  initialData: state.payments.initialData,
  isLoading: state.payments.isLoading,
  isError: state.payments.isError
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const columns = [
  "ParamX",
  "Type",
  "Date",
  "Amount",
  "Currency",
  "Status",
  "Card Number",
  "Expiration Date"
];

const options = {
  selectableRows: 'none'
};

function PaymentsTable({ payments, initialData, isError, isLoading, actions }) {
  let history = useHistory();
  options.onRowClick = (rowData, rowMeta) => {
    const payment = initialData.find(row => row.ParamX === rowData[0]);
    if (payment) {
      history.push(`/payments/${payment.ID}`);
    }
    else {
      console.log('Unknown payment')
    }
  }

  if (payments === null && !isError && !isLoading) {
    actions.fetchPayments();
    return <LoadingScreen />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error</div>
  }
 
  return (        
    <MUIDataTable
      title={"Payments"}
      data={payments}
      columns={columns}
      options={options}
    />
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsTable)