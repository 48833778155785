import { combineReducers } from 'redux'
import subscriptions from './subscriptions'
import subscription from './subscription'
import payments from './payments'
import payment from './payment'
import accounts from './accounts'
import account from './account'

export default combineReducers({
  subscriptions,
  subscription,
  payments,
  payment,
  accounts,
  account
});


