import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  iconColor: {
    color: '#fff'
  }
}));

const LinkStyle = { textDecoration: 'none' };
const links = [
  {
    url: '/dashboard',
    text: 'Dashboard',
    icon: <DashboardIcon />
  },
  {
    url: '/subscriptions',
    text: 'Subscriptions',
    icon: <CreditCardIcon />
  },
  {
    url: '/payments',
    text: 'Payments',
    icon: <MonetizationOnIcon />
  },
  {
    url: '/accounts',
    text: 'Accounts',
    icon: <SupervisorAccountIcon />
  },
  {
    url: '/reports',
    text: 'Reports',
    icon: <TrendingUpIcon />
  }
]

export default function MainListItems() {
  const location = useLocation();
  const pos = location.pathname.indexOf('/', 1);
  const selectedMenuItem = pos === -1 ? location.pathname : location.pathname.substring(0, pos);
  const [selected, setSelected] = useState(selectedMenuItem === '/' ? '/dashboard' : selectedMenuItem);
  const classes = useStyles();

  return (
    <>
      {links.map(link => (
        <Link key={link.url} to={link.url} style={LinkStyle} onClick={() => setSelected(link.url)}>
          <ListItem button style={{color: '#FFF', backgroundColor: selected === link.url ? '#00A8FF' : ''}}>
            <ListItemIcon className={classes.iconColor}>
              {link.icon}
            </ListItemIcon>
            <ListItemText color="secondary" primary={link.text} />
          </ListItem>
        </Link>
      ))}
    </>
);
}
