import React from 'react';
import { Route, Switch } from 'react-router-dom'
import Auth from './config/Auth'
import Layout from './layout/Layout'
import Subscriptions from './components/SubscriptionsTable'
import Subscription from './components/Subscription'
import Payments from './components/PaymentsTable'
import Payment from './components/Payment'
import Reports from './components/Reports'
import Accounts from './components/AccountsTable'
import Account from './components/Account'
import './App.css'

function App() {
  return (
    <Auth>
      <Layout>
        <Switch>
          <Route path="/dashboard">
            <div>Dashboard</div>
          </Route>
          <Route exact path="/subscriptions">
            <Subscriptions/>
          </Route>
          <Route exact path="/subscriptions/:id">
            <Subscription />
          </Route>
          <Route exact path="/payments">
            <Payments />
          </Route>
          <Route exact path="/payments/:id">
            <Payment />
          </Route>
          <Route exact path="/accounts">
            <Accounts />
          </Route>
          <Route exact path="/accounts/:id">
            <Account />
          </Route>
          <Route path="/reports">
            <Reports />
          </Route>
          <Route path="/">
            <div>Dashboard</div>
          </Route>
        </Switch>
      </Layout>
    </Auth>
  );
}

export default App;
