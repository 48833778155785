import * as types from '../actionTypes'
import moment from 'moment'

export const requestPayments = () => ({ type: types.REQUEST_PAYMENTS });
export const receivePayments =
  (payments, initialPayments, isError) => ({
    type: types.RECEIVE_PAYMENTS,
    payments,
    initialPayments,
    isError
  })

export const fetchPayments = () => async dispatch => {
  dispatch(requestPayments());
  try {
      const url = `/admin/payments`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND}${url}`);
      const responseBody = await response.json();
      const mappedData = [];
      responseBody.map(row => {
        mappedData.push([
          row.ParamX,
          row.PaymentType,
          moment(row.Date).format("MMMM Do YYYY"),
          row.Amount,
          row.Currency,
          row.PaymentStatus,
          row.CCNumber,
          row.CCExpDate
        ])
      });

      dispatch(receivePayments(mappedData, responseBody, false));
  } catch (error) {
    console.error(error);
    dispatch(receivePayments(null, true));
  }
}