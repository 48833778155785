import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../redux/actions/accountsActions'
import { bindActionCreators } from 'redux'
import MUIDataTable from "mui-datatables"
import { useHistory } from "react-router-dom"
import LoadingScreen from '../elements/LoadingScreen'

const mapStateToProps = state => ({
  accounts: state.accounts.data,
  isLoading: state.accounts.isLoading,
  isError: state.accounts.isError
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const columns = ["ID", "Creation Date", "First Name", "Last Name", "Email", "Phone", "Order Language"];

const options = {
  selectableRows: 'none'
};

function AccountsTable({ accounts, isError, isLoading, actions }) {
  let history = useHistory();
  options.onRowClick = (rowData, rowMeta) => {
    history.push(`/accounts/${rowData[0]}`);
  }

  if (accounts === null && !isError && !isLoading) {
    actions.fetchAccounts();
    return <LoadingScreen />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error</div>
  }

  return (        
    <MUIDataTable
      title={"Accounts"}
      data={accounts}
      columns={columns}
      options={options}
    />
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountsTable)