import { REQUEST_PAYMENTS, RECEIVE_PAYMENTS } from '../actionTypes';

const initialState = {
  data: null,
  initialData: null,
  isLoading: false,
  isError: false
}

export default (state = initialState, action) => {
  switch(action.type) {
    case REQUEST_PAYMENTS:
      return {
        ...state,
        isLoading: true,
      }
    case RECEIVE_PAYMENTS:
      return {
        ...state,
        data: action.payments,
        initialData: action.initialPayments,
        isError: action.isError,
        isLoading: false
      }
    default: {
      return state;
    }
  }
}

