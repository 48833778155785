import React from 'react'
import {useParams} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import * as actions from '../redux/actions/accountActions'
import { bindActionCreators } from 'redux'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import LoadingScreen from '../elements/LoadingScreen'
import FaceIcon from '@material-ui/icons/Face'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import MUIDataTable from "mui-datatables"

const mapStateToProps = state => ({
  account: state.account.data,
  isLoading: state.account.isLoading,
  isError: state.account.isError
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px'
  },
  iconContainer: {
    display: 'flex'
  },
  icon: {
    marginRight: '10px'
  },
  subtitle: {
    marginBottom: '15px'
  }
}));

// Orders table settings
const columns = ["ID", "Date", "Amount", "Currency", "Status"];
const options = {
  selectableRows: 'none'
};

// Payments table settings
const columnsPayments = [
  "ParamX",
  "Date",
  "Amount",
  "Currency",
  "Status",
  "Type", 
  "Card Number",
  "Expiration Date"
];
const optionsPaymenst = {
  selectableRows: 'none'
};

function Account({ account, isError, isLoading, actions }) {
  const classes = useStyles();
  const { id } = useParams();

  // No account in the store
  if (account === null && !isError && !isLoading) {
    actions.fetchAccount(id);
    return <LoadingScreen />;
  }

  // Account changed
  if (account && account.Details.ID !== Number(id) && !isLoading && !isError) {
    actions.fetchAccount(id);
    return <LoadingScreen />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error</div>
  }

  return (
    <Paper className={classes.root} square elevation={4}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h4" noWrap>
            Account # {id}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography component="h6" variant="h6" noWrap className={classes.subtitle}>
            Details
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom className={classes.iconContainer}>
            <FaceIcon className={classes.icon} /> {account.Details.FirstName} {account.Details.LastName} {account.Details.Country ? `, ${account.Details.Country}` : ''}
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom className={classes.iconContainer}>
            <EmailIcon className={classes.icon} /> {account.Details.Email}
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom className={classes.iconContainer}>
            <PhoneIcon className={classes.icon} /> {account.Details.Phone}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            title="Orders"
            data={account.Orders}
            columns={columns}
            options={options}
          />
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            title="Payments"
            data={account.Payments}
            columns={columnsPayments}
            options={optionsPaymenst}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account)
