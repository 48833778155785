import { REQUEST_ACCOUNTS, RECEIVE_ACCOUNTS } from '../actionTypes';

const initialState = {
  data: null,
  isLoading: false,
  isError: false
}

export default (state = initialState, action) => {
  switch(action.type) {
    case REQUEST_ACCOUNTS:
      return {
        ...state,
        isLoading: true,
      }
    case RECEIVE_ACCOUNTS:
      return {
        ...state,
        data: action.accounts,
        isError: action.isError,
        isLoading: false
      }
    default: {
      return state;
    }
  }
}

