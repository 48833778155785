import * as types from '../actionTypes'
import moment from 'moment'

export const requestSubscription = () => ({ type: types.REQUEST_SUBSCRIPTION });
export const receiveSubscription =
  (subscription, isError) => ({
    type: types.RECEIVE_SUBSCRIPTION,
    subscription,
    isError
  })

export const fetchSubscription = (id) => async dispatch => {
  dispatch(requestSubscription());
  try {
      const url = `/admin/subscriptions/${id}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND}${url}`);
      let responseBody = await response.json();
      if (responseBody.Details) {
        responseBody.Details = {
          ...responseBody.Details,
          Note: responseBody.Details.Note.String,
        }
      }
      if (responseBody.Payments) {
        responseBody.Payments = responseBody.Payments.map(payment => [
          payment.ParamX,
          moment(payment.Date).format("MMMM Do YYYY"),
          payment.Amount,
          payment.Currency,
          payment.Status,
          payment.CCNumber,
          payment.CCExpDate
        ])
      }
      dispatch(receiveSubscription(responseBody, false));
  } catch (error) {
    console.error(error);
    dispatch(receiveSubscription(null, true));
  }
}