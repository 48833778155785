import * as types from '../actionTypes'
import moment from 'moment'

export const requestAccount = () => ({ type: types.REQUEST_ACCOUNT });
export const receiveAccount =
  (account, isError) => ({
    type: types.RECEIVE_ACCOUNT,
    account,
    isError
  })

export const fetchAccount = (id) => async dispatch => {
  dispatch(requestAccount());
  try {
      const url = `/admin/accounts/${id}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND}${url}`)
      let responseBody = await response.json();

      // Create orders array
      responseBody.Orders = responseBody.Orders.map(order => ({
        ...order,
        Date: moment(order.Date).format("MMMM Do YYYY")
      }));

      // Create payments array
      responseBody.Payments = responseBody.Payments.map(payment => [
        payment.ParamX,
        moment(payment.Date).format("MMMM Do YYYY"),
        payment.Amount,
        payment.Currency,
        payment.PaymentStatus,
        payment.PaymentType,
        payment.CCNumber,
        payment.CCExpDate
      ]);

      dispatch(receiveAccount(responseBody, false));
  } catch (error) {
    console.error(error);
    dispatch(receiveAccount(null, true));
  }
}