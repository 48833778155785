import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles({
  root: {
    padding: '30px 40px',
    height: 'calc(100vh - 200px)'
  },
  button: {
    backgroundColor: '#E84118',
    color: '#FFF',
    marginLeft: '20px',
    '&:hover': {
      backgroundColor: '#FFF',
      color: '#E84118',
    }
  }
});

export default function Reports() {
  const classes = useStyles();

  return (   
    <Paper square elevation={4} className={classes.root}>     
      <Typography component="h4" variant="h4" noWrap paragraph>
        Reports
      </Typography>
      <Grid container>
      <Typography component="div" variant="subtitle1" noWrap paragraph>
          Monthly Failed Payments
        </Typography>
        <Button className={classes.button} color='error' variant="contained">
          Export
        </Button>
      </Grid>
    </Paper>
  )
}