import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#222C3C',
    },
    secondary: {
      main: '#00A8FF',
    },
    error: {
      main: '#E84118',
    },
    background: {
      default: '#DCDDE1',
    },
  },
  // overrides: { MUIDataTableToolbar: { root: { display: 'none' } } }
});

export default theme