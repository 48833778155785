import {
  REQUEST_SUBSCRIPTIONS,
  RECEIVE_SUBSCRIPTIONS,
  CANCEL_SUBSCRIPTION,
  SUBSCRIPTION_CANCELLED,
  SET_MODAL_STATUS
} from '../actionTypes';
import { cancelModalStatuses } from '../../const'

const initialState = {
  data: null,
  isLoading: false,
  isError: false,
  cancelModalStatus: cancelModalStatuses.buttons
}

export default (state = initialState, action) => {
  switch(action.type) {
    case REQUEST_SUBSCRIPTIONS:
      return {
        ...state,
        isLoading: true,
      }
    case RECEIVE_SUBSCRIPTIONS:
      return {
        ...state,
        data: action.subscriptions,
        isError: action.isError,
        isLoading: false
      }
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelModalStatus: cancelModalStatuses.cancelling
      }
    case SUBSCRIPTION_CANCELLED: {
      return {
        ...state,
        cancelModalStatus: action.isError ? 
          cancelModalStatuses.cancelError :
          cancelModalStatuses.cancelSuccess
      }
    }
    case SET_MODAL_STATUS: {
      return {
        ...state,
        cancelModalStatus: action.status
      }
    }
    default: {
      return state;
    }
  }
}

