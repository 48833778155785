import React from 'react'
import moment from 'moment'
import {useParams} from 'react-router-dom'
import { connect } from 'react-redux'
import * as actions from '../redux/actions/subscriptionActions'
import { bindActionCreators } from 'redux'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import LoadingScreen from '../elements/LoadingScreen'
import Divider from '@material-ui/core/Divider'
import FaceIcon from '@material-ui/icons/Face'
import EmailIcon from '@material-ui/icons/Email'
import PhoneIcon from '@material-ui/icons/Phone'
import MUIDataTable from "mui-datatables"
import { enums } from '../enums'

const mapStateToProps = state => ({
  subscription: state.subscription.data,
  isLoading: state.subscription.isLoading,
  isError: state.subscription.isError
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

// Payments table settings
const columns = [
  "ParamX",
  "Date",
  "Amount",
  "Currency",
  "Status",
  "Card Number",
  "Expiration Dare"
];
const options = {
  selectableRows: 'none'
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px'
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    margin: '0 20px 20px 20px'
  },
  subtitle: {
    marginBottom: '15px'
  },
  iconContainer: {
    display: 'flex'
  },
  icon: {
    marginRight: '10px'
  }
}));

function Subscription({ subscription, isError, isLoading, actions }) {
  const classes = useStyles();
  const { id } = useParams();

  // No subscription
  if (subscription === null && !isError && !isLoading) {
    actions.fetchSubscription(id);
    return <LoadingScreen />;
  }

  // Subscription changed
  if (subscription && subscription.Details.ID !== Number(id) && !isLoading && !isError) {
    actions.fetchSubscription(id);
    return <LoadingScreen />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error</div>
  }

  return (
    <Paper square elevation={4} className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h4" color="inherit" noWrap>
            Subscription # {id}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography component="h6" variant="h6" color="inherit" noWrap className={classes.subtitle} >
            Details
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom>
            {subscription.Details.Amount} {subscription.Details.Currency} for <b>{enums[subscription.Details.OrderType]}</b>
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom>
            <b>Status:</b> {subscription.Details.Status}
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom>
            <b>Started:</b> {moment(subscription.Details.DateCreated).format("MMMM Do YYYY")} 
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom>
          <b>Last Paid:</b> {moment(subscription.Details.LastPayment).format("MMMM Do YYYY")}
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap >
            <b>Note:</b> {subscription.Details.Note}
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem className={classes.divider}/>
        <Grid item xs={5}>
          <Typography component="h6" variant="h6" color="inherit" noWrap className={classes.subtitle}>
            Account
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom className={classes.iconContainer}>
            <FaceIcon className={classes.icon} /> {subscription.Account.FirstName} {subscription.Account.LastName} {subscription.Account.Country ? `, ${subscription.Account.Country}` : ''}
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom className={classes.iconContainer}>
            <EmailIcon className={classes.icon} /> {subscription.Account.Email}
          </Typography>
          <Typography component="p" variant="body1" color="inherit" noWrap gutterBottom className={classes.iconContainer}>
            <PhoneIcon className={classes.icon} /> {subscription.Account.Phone}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider}/>
            <MUIDataTable
              title="Payments"
              data={subscription.Payments}
              columns={columns}
              options={options}
            />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Subscription)
