import { REQUEST_PAYMENT, RECEIVE_PAYMENT } from '../actionTypes';

const initialState = {
  data: null,
  isLoading: false,
  isError: false
}

export default (state = initialState, action) => {
  switch(action.type) {
    case REQUEST_PAYMENT:
      return {
        ...state,
        isLoading: true,
      }
    case RECEIVE_PAYMENT:
      return {
        ...state,
        data: action.payment,
        isError: action.isError,
        isLoading: false
      }
    default: {
      return state;
    }
  }
}

