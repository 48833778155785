import * as types from '../actionTypes'

export const requestPayment = () => ({ type: types.REQUEST_PAYMENT });
export const receivePayment =
  (payment, isError) => ({
    type: types.RECEIVE_PAYMENT,
    payment,
    isError
  })

export const fetchPayment = (id) => async dispatch => {
  dispatch(requestPayment());
  try {
      const url = `/admin/payments/${id}`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND}${url}`);
      let responseBody = await response.json();
      dispatch(receivePayment(responseBody, false));
  } catch (error) {
    console.error(error);
    dispatch(receivePayment(null, true));
  }
}