import React, { useState, useEffect } from 'react'
import Keycloak from 'keycloak-js'
import UserContext from './UserContext'
import LoadingScreen from '../elements/LoadingScreen'

function Auth(props) {
  const [auth, setAuth] = useState({ keycloak: null, authenticated: false })

  useEffect(() => {    
    const keycloak = Keycloak('/keycloak.json');
    keycloak.init({onLoad: 'login-required'})
      .then(authenticated => {
          keycloak.loadUserProfile().then(function() {
            const profile = {
              username: keycloak.profile.username,
              firstName: keycloak.profile.firstName,
              lastName: keycloak.profile.lastName,
              email: keycloak.profile.email
            }
            setAuth({ 
              keycloak,
              authenticated,
              profile
            })
          })
      })
  }, [])
  if (auth.keycloak) {
    if (auth.authenticated) {
      return (
        <UserContext.Provider value={auth.profile}>
          {props.children}
        </UserContext.Provider>
      )
    } else {
      return (
        <>
          <div>Error...</div>
        </>
      )
    } 
  } else {
    return (
      <div>
        <LoadingScreen />
      </div>
    )
  }
}

export default Auth
