import * as types from '../actionTypes'
import moment from 'moment'

export const requestSubscriptions = () => ({ type: types.REQUEST_SUBSCRIPTIONS });
export const receiveSubscriptions =
  (subscriptions, isError) => ({
    type: types.RECEIVE_SUBSCRIPTIONS,
    subscriptions,
    isError
  })

export const fetchSubscriptions = () => async dispatch => {
  dispatch(requestSubscriptions());
  try {
      const url = `/admin/subscriptions`;
      const response = await fetch(`${process.env.REACT_APP_BACKEND}${url}`);
      const responseBody = await response.json();
      const mappedData = responseBody.map(row => [
          row.ID,
          moment(row.Date).format("MMMM Do YYYY"),
          row.Amount,
          row.Currency,
          moment(row.PaymentDate).format("MMMM Do YYYY"),
          row.Status,
          row.Flag.String,
          row.FirstName,
          row.LastName,
          row.OrderLanguage
        ]);
      dispatch(receiveSubscriptions(mappedData, false));
  } catch (error) {
    console.error(error);
    dispatch(receiveSubscriptions(null, true));
  }
}

export const requestCancelSubscriptions = () => ({ type: types.CANCEL_SUBSCRIPTION});
export const subscriptionCancelled =
  (isError) => ({
    type: types.SUBSCRIPTION_CANCELLED,
    isError
  })

export const setModalStatus = (status) => ({ 
  type: types.SET_MODAL_STATUS,
  status
});

export const cancelSubscription = (id) => async dispatch => {
  dispatch(requestCancelSubscriptions());
  try {
    const url = `/orders/update/${id}/cancelled`;
    const response = await fetch(`${process.env.REACT_APP_BACKEND}${url}`);
    const isError = response.status !== 200 ? true : false;
    dispatch(subscriptionCancelled(isError));
  } catch (error) {
    console.error(error);
    dispatch(subscriptionCancelled(true));
  }
}

